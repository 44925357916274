import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import ReactFullpage from '@fullpage/react-fullpage';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import SEO from '../components/seo';
import localize from '../components/localize';
import RichText from '../components/richtext';
import Footer from '../components/footer';

import HolDesign from '../static/assets/hol-design.svg';
import Branding from '../static/assets/branding.svg';
import ArrowStarArrow from '../static/assets/arrow-star-arrow.svg';
import Arrow from '../static/assets/arrow.svg';
import StarBrackets from '../static/assets/star-in-brackets.svg';
import SquareCircleBlack from '../static/assets/square-circle-black.svg';
import Star from '../static/assets/star.svg';

const StrategiePage = ({ data }) => {
  const sectionOne = data.sectionOne.edges.map(edge => edge.node);
  const sectionTwo = data.sectionTwo.edges.map(edge => edge.node);
  const sectionThree = data.sectionThree.edges.map(edge => edge.node);

  const [activeSection, setActiveSection] = useState();
  const afterLoad = (origin, destination) => {
    setActiveSection(destination.index);
  };

  return (
    <>
      <Layout
        current="STRATEGY"
        dePath="/strategie"
        enPath="/en/strategie"
        hideHeader={activeSection !== 0}
        hideNav={activeSection === 3}
        disableUp={activeSection === 0}
        disableDown={activeSection === 2}
        onClickUp={() => window.fullpage_api.moveSectionUp()}
        onClickDown={() => window.fullpage_api.moveSectionDown()}
      >
        <SEO siteTitle="STRATEGY" />
        <ReactFullpage
          licenseKey={process.env.GATSBY_FULLPAGE_LICENSE}
          responsiveWidth={1020}
          scrollOverflow
          autoScrolling
          scrollingSpeed={600}
          easingcss3="cubic-bezier(0.700, 0.000, 1.000, 1.000)"
          afterLoad={afterLoad}
          render={() => (
            <div id="fullpage-wrapper">
              <StyledSectionOne className="section light fp-auto-height-responsive">
                <div className="inner">
                  <div className="left">
                    <button
                      className="emoji"
                      type="button"
                      onClick={() => window.fullpage_api.moveTo(2)}
                    >
                      <HolDesign />
                    </button>
                  </div>
                  <div className="center">
                    <h2 className="big centered">
                      <RichText blocks={sectionOne[0]._rawTitle} />
                    </h2>
                    <div className="centered">
                      <div className="arrow-star">
                        <ArrowStarArrow />
                      </div>
                      <RichText blocks={sectionOne[0]._rawDescription} />
                    </div>
                  </div>
                  <div className="right">
                    <button
                      type="button"
                      className="emoji"
                      onClick={() => window.fullpage_api.moveTo(3)}
                    >
                      <Branding />
                    </button>
                  </div>
                </div>
              </StyledSectionOne>
              <StyledSectionTwo className="section light fp-auto-height-responsive">
                <div className="inner">
                  <div className="light section-1">
                    <div className="left right-aligned">
                      <p className="overline japan">
                        {sectionTwo[0].overline1}
                      </p>
                      <RichText blocks={sectionTwo[0]._rawDescription1} />
                      <Img
                        className="img"
                        fluid={sectionTwo[0].image1.image.asset.fluid}
                        alt={sectionTwo[0].image1.alt}
                      />
                    </div>
                    <div className="center">
                      <div className="emoji">
                        <HolDesign />
                      </div>
                      <div className="werte centered">
                        <RichText blocks={sectionTwo[0]._rawWerte} />
                        <div className="arrows">
                          <Arrow />
                          <Arrow />
                          <Arrow />
                          <Arrow />
                          <Arrow />
                        </div>
                      </div>
                    </div>
                    <div className="right">
                      <div className="overline">
                        <RichText blocks={sectionTwo[0]._rawOverline2} />
                        <span className="emojis">
                          <SquareCircleBlack />
                          <Star />
                          <Star />
                          <br />
                          <SquareCircleBlack />
                          <br />
                          <SquareCircleBlack />
                        </span>
                      </div>
                      <RichText blocks={sectionTwo[0]._rawDescription2} />
                      <Img
                        className="img"
                        fluid={sectionTwo[0].image2.image.asset.fluid}
                        alt={sectionTwo[0].image2.alt}
                      />
                    </div>
                  </div>
                  <div className="dark section-2">
                    <div className="row-1">
                      <div className="row-left">
                        <RichText blocks={sectionTwo[0]._rawSubline1} />
                        <RichText blocks={sectionTwo[0]._rawSubline2} />
                      </div>
                      <div className="row-center centered">
                        <StarBrackets />
                      </div>
                      <div className="row-right">
                        <RichText blocks={sectionTwo[0]._rawSubline4} />
                        <RichText blocks={sectionTwo[0]._rawSubline5} />
                      </div>
                    </div>
                    <div className="row-2">
                      <div className="row-left">
                        <RichText blocks={sectionTwo[0]._rawSubdescription1} />
                        <RichText blocks={sectionTwo[0]._rawSubdescription2} />
                      </div>
                      <div className="row-center centered">
                        <RichText blocks={sectionTwo[0]._rawSubline3} />
                      </div>
                      <div className="row-right">
                        <RichText blocks={sectionTwo[0]._rawSubdescription3} />
                        <RichText blocks={sectionTwo[0]._rawSubdescription4} />
                      </div>
                    </div>
                  </div>
                </div>
              </StyledSectionTwo>
              <StyledSectionThree className="section light fp-auto-height-responsive">
                <div className="inner">
                  <div className="row-1">
                    <Img
                      className="img-small"
                      fluid={sectionThree[0].image1.image.asset.fluid}
                      alt={sectionThree[0].image1.alt}
                    />
                    <div className="centered desc-1">
                      <RichText blocks={sectionThree[0]._rawDescription1} />
                    </div>
                    <Img
                      className="img-small"
                      fluid={sectionThree[0].image2.image.asset.fluid}
                      alt={sectionThree[0].image2.alt}
                    />
                  </div>
                  <div className="row-2">
                    <Img
                      className="img"
                      fluid={sectionThree[0].image3.image.asset.fluid}
                      alt={sectionThree[0].image3.alt}
                    />
                    <div className="emoji-branding">
                      <Branding />
                    </div>
                    <Img
                      className="img"
                      fluid={sectionThree[0].image4.image.asset.fluid}
                      alt={sectionThree[0].image4.alt}
                    />
                  </div>
                  <div className="row-3">
                    <div className="desc-2">
                      <RichText blocks={sectionThree[0]._rawDescription2} />
                    </div>
                    <div className="desc-3 right-aligned">
                      <RichText blocks={sectionThree[0]._rawDescription3} />
                    </div>
                  </div>
                </div>
              </StyledSectionThree>
              <Footer />
            </div>
          )}
        />
      </Layout>
    </>
  );
};

const StyledSectionOne = styled.section`
  .inner {
    padding: var(--spacing-S) var(--spacing-XS);
    display: flex;

    .left,
    .right {
      flex: 1;
    }

    .center {
      flex: 2;
    }

    .arrow-star {
      padding: var(--spacing-M) 0;
      svg {
        height: 50%;
      }
    }

    .emoji {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        min-width: 200%;
      }
    }

    @media (max-width: 1020px) {
      flex-direction: column;
      padding: var(--spacing-M) var(--spacing-XXS);

      .emoji {
        svg {
          width: 70%;
          height: auto;
        }
      }

      .left,
      .right,
      .center {
        flex: 0;
      }

      .left {
        order: 1;
      }

      .right {
        order: 2;
      }
    }
  }
`;

const StyledSectionTwo = styled.section`
  .inner {
    display: flex;
    flex-direction: column;

    .section-1 {
      padding: var(--spacing-L) var(--spacing-XS);
      display: flex;
      height: 100vh;
    }

    .left,
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .emojis {
      svg {
        width: 5%;
        height: auto;
      }
    }

    .left {
      align-items: flex-end;
    }

    .center {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;

      .werte {
        padding: var(--spacing-M);
        .arrows {
          padding: var(--spacing-XS) 0;
        }
      }
    }

    .overline {
      height: var(--spacing-XL);
    }

    .japan {
      font-family: var(--font-3);
    }

    .img {
      width: 75%;
      margin: var(--spacing-M) 0;
    }

    .emoji {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--spacing-XL) var(--spacing-M);
      svg {
        min-width: 200%;
      }
    }

    .row-1,
    .row-2 {
      display: flex;
      padding: 0 var(--spacing-XS);
    }

    .row-1 {
      padding: var(--spacing-M) 0;
    }

    .row-2 {
      padding-bottom: var(--spacing-M);
    }

    .row-left,
    .row-center,
    .row-right {
      flex: 1;
      width: 100%;
    }

    .row-left,
    .row-right {
      display: flex;
    }

    .row-left {
      text-align: right;
      p {
        width: 100%;
        padding-left: var(--spacing-XS);
      }
    }

    .row-center {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: var(--spacing-M);
      svg {
        height: 50%;
        fill: white;
        g {
          path {
            fill: white;
          }
        }
      }
    }

    .row-right {
      p {
        width: 100%;
        padding-right: var(--spacing-XS);
      }
    }

    @media (max-width: 1020px) {
      flex-direction: column;

      .section-1 {
        padding: var(--spacing-XXS);
        flex-direction: column;
        height: auto;
      }

      .img {
        display: none;
      }

      .left,
      .right,
      .center {
        flex: 0;
      }

      .left {
        order: 1;
        align-items: flex-start;
      }

      .right {
        order: 2;
        padding-top: var(--spacing-S);
      }

      .center {
        order: 3;

        .werte {
          padding: var(--spacing-XS);
        }
      }

      .overline {
        height: auto;
      }

      .emoji {
        padding: var(--spacing-L) var(--spacing-S);
        svg {
          width: 70%;
        }
      }

      .row-1,
      .row-2 {
        flex-direction: column;
        padding: var(--spacing-XS);
      }

      .row-left,
      .row-right {
        flex-direction: column;
        text-align: center;
        p {
          padding: 0;
          padding-bottom: var(--spacing-XXS);
        }
      }

      .row-center {
        justify-content: center;
        align-items: center;
        padding-bottom: var(--spacing-XXS);
        svg {
          height: var(--spacing-XS);
        }
      }
    }
  }
`;

const StyledSectionThree = styled.section`
  .inner {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-L) var(--spacing-XS);

    .row-1,
    .row-2,
    .row-3 {
      display: flex;
      padding-bottom: var(--spacing-XXS);
    }

    .row-1 {
      padding-bottom: var(--spacing-XXS);
    }

    .row-3 {
      padding: var(--spacing-S) var(--spacing-XL);
      .desc-2 {
        padding-right: var(--spacing-M);
      }

      .desc-3 {
        padding-left: var(--spacing-M);
      }
    }

    .img {
      width: 50%;
    }

    .img-small {
      width: 25%;
    }

    .desc-1 {
      width: 100%;
      display: flex;
      justify-content: center;
      p {
        width: 50%;
      }
    }

    .emoji-branding {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: auto;
      svg {
        min-width: 100%;
      }
    }

    @media (max-width: 1020px) {
      padding: var(--spacing-S) var(--spacing-XXS);

      .row-1,
      .row-2,
      .row-3 {
        flex-direction: column;
        padding: 0;
      }

      .desc-1 {
        order: -1;
        margin-bottom: var(--spacing-XXS);
        p {
          width: 100%;
        }
      }

      .row-3 {
        .desc-2,
        .desc-3 {
          width: 100%;
          padding-right: var(--spacing-XXS);
        }
        .desc-3 {
          padding-left: 0;
        }
      }

      .img,
      .img-small {
        width: 100%;
        margin-bottom: var(--spacing-XXS);
      }

      .emoji-branding {
        order: 1;
        margin-bottom: var(--spacing-XXS);
        svg {
          min-width: 200%;
        }
      }
    }
  }
`;

export const query = graphql`
  query Strategie {
    sectionOne: allSanityStrategieSectionOne {
      edges {
        node {
          _rawTitle
          _rawDescription
        }
      }
    }
    sectionTwo: allSanityStrategieSectionTwo {
      edges {
        node {
          overline1
          _rawOverline2
          _rawDescription1
          image1 {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          _rawDescription2
          image2 {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          _rawWerte
          _rawSubline1
          _rawSubline2
          _rawSubline3
          _rawSubline4
          _rawSubline5
          _rawSubdescription1
          _rawSubdescription2
          _rawSubdescription3
          _rawSubdescription4
        }
      }
    }
    sectionThree: allSanityStrategieSectionThree {
      edges {
        node {
          _rawDescription1
          _rawDescription2
          _rawDescription3
          image1 {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          image2 {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          image3 {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          image4 {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

StrategiePage.propTypes = {
  data: PropTypes.object,
};

export default localize(StrategiePage);
